export default {
  account: {
    components1: "Account list",
    components2: "Account/nickname search",
    components3: "messages",
    components4: "loading...",
    components5:
      "This feature is currently not supported, please upgrade your purchase package",
  },
  fans: {
    components1: "Dialogue",
    components2: "Account/nickname/remarks search",
    components3: "messages",
    components4: "loading...",
    components5: "Fan information",
    components6: "Fan ID:",
    components7: "Nickname notes:",
    components8: "Please enter",
    components9: "Remark",
    components10: "Please enter the remarks, no more than 200 characters ...",
    components11: "Choose up to 5 tags",
    components12: "Label",
    components13: "Please select the label",
    components14: "Follow -up record",
    components15: "Please enter the follow -up record",
    components16: "Confirm",
    components17: "Cancel",
    components18: "Add follow-up record",
    components19: "Modification successful",
    components20: "Failure to modify",
    components21: "Added successfully",
    components22: "Add failed",
    components23: "Delete",
    components24: "Delete failed",
    components25: "Confirm deletion?",
  },
  msg: {
    components1: "loading...",
    components2: "View context",
    components3: "[Picture]",
    components4: "[Video]",
    components5: "[Audio]",
    components6: "[Document]",
    components7: "[Business card]",
    components8: "[Other]",
  },
  translateSetting: {
    components1: "Translation settings",
    components2: "Automatic translation of chat messages",
    components3: "target language",
    components4: "Select language",
    components5: "Translation route",
    components6: "Choose a translation route",
    components7: "Source language",
    components8: "Select source language",
    components9: "Keep",
    components10: "Reset",
    components11: "Be successful",
    components12: "Save failed",
    components13: "System testing",
  },
  anti: {
    antiContent01: "Verify that the address is official",
    antiContent02: "for",
    antiContent03: "Official Address",
    antiContent04: "Unofficial Address",
    antiContent05: "Query",
    antiContent06:
      "Regardless of any transaction, please be sure to check the other party's TG number/channel/domain name first",
    antiContent07: "Please enter",
    antiContent08: "Anti-fraud query",
  },
};
