export default {
    components1:"返回",
    components2:"该账号为后台创建，请",
    components3:"联系客服",
    components4:"修改密码",
    components5: '确 定',
    components6: '取 消',
    components7: '1.什么是邀请码?',
    components8: '主管在管理端创建用于登录客户端的登录码，可提供给同事或合作伙伴使用。',
    components9: '2.如何创建邀请码（子账号）?',
    components10: '点击“新建子账号”选项，进而为邀请码（子账号）配置相应的功能权限，如社交平台、端口、备注等。',
    components11: '我已了解',
    components12: '下一步',
    components13: '关闭提示',
    components14: '是否关闭引导下次不在弹出?',
    components15: '关闭失败',
}