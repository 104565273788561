export default {
  promoList1: 'Revenue Statistics',
  promoList2: 'Recommended users',
  promoList3: 'State',
  promoList4: 'All',
  promoList5: 'Register',
  promoList6: 'Paid',
  promoList7: 'Payment amount($)',
  promoList8: 'Recommended user payment times',
  promoList9: 'Registration time',
  promoList10: 'Register',
  promoList11: 'Paid',
  promoList12: 'Transaction details',
  promoList13: 'Your commission($)',
};
