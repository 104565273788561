<template>
  <div>
    <el-form ref="formRef" :model="formData" :rules="rules">
      <el-form-item label="知识库名称：" prop="name">
        <el-input v-model="formData.name" placeholder="请输入仓库名称" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup lang="ts">
import {onUnmounted, reactive, ref} from "vue"
import { useCommon } from '/@/hooks'

const { emitter } = useCommon()

const formRef = ref(null)

const emits = defineEmits(['submit'])

const props = defineProps({
  name: {
    type: String,
    default: ''
  }
})

const rules = {
  name: [
    { required: true, message: '请输入知识库名称', trigger: 'blur' },
    { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
  ]
}

const formData = reactive({
  name: props.name,
})

onUnmounted(() => {
  emitter.off('submit')
})

emitter.on('submit', async () => {
  try {
    await formRef.value.validate()
  } catch (e) {
    return
  }
  emits('submit', formData)
})

</script>

<style scoped>

</style>