import {
    ICreateAiAgentRes,
    IGetAiAgentListRes,
    IAiTrainChatReq,
    IAiTrainChatRes,
    GetAiAgentDataReq,
    GetAiAgentDataRes,
    AiChatReq,
    AiChatRes,
    IChangeAgentNameReq,
    IGetAiAgentReleaseListReq,
    IGetAiAgentReleaseListRes,
    ICreateAiAgentRelease,
    IRevokeRelease,
    IGetAiRepoListReq,
    ICreateAiRepoReq,
    IUpdateAiRepoItemReq,
    IDeleteAiRepoItemReq,
    IDeleteAiRepoReq,
    IGetListAiRepoItemReq,
    IAiRepoChangeOpenStatusReq,
    IAddAiRepoReq
} from './../../type/views/ai';
import axios, { Response } from "/@/utils/request";

export const createAiAgent = () => {
    return axios<Response<ICreateAiAgentRes>>({
        url: '/base/comm/createAiAgent',
        method: 'post',
    })
}

export const changeRepoName = (data: {
    aiRepoId: number,
    name: string
}) => {
    return axios<Response<any>>({
        url: '/base/comm/changeRepoName',
        method: 'post',
        data
    })
}

export const getAiAgentList = () => {
    return axios<Response<IGetAiAgentListRes>>({
        url: '/base/comm/getAiAgentList',
        method: 'post',
    })
}

export const aiTrainChat = (data: IAiTrainChatReq) => {
    return axios<Response<IAiTrainChatRes>>({
        url: '/base/comm/aiTrainChat',
        method: 'post',
        data
    })
}

export const getAiAgentData = (data: GetAiAgentDataReq) => {
    return axios<Response<GetAiAgentDataRes>>({
        url: '/base/comm/getAiAgentData',
        method: 'post',
        data
    })
}

export const resetAiAgent = (aiAgentId: number) => {
    return axios<Response<any>>({
        url: '/base/comm/resetAiAgent',
        method: 'post',
        data: {
            aiAgentId
        }
    })
}

export const delAiAgent = (aiAgentId: number) => {
    return axios<Response<any>>({
        url: '/base/comm/delAiAgent',
        method: 'post',
        data: {
            aiAgentId
        }
    })
}

export const aiChat = (data: AiChatReq) => {
    return axios<Response<AiChatRes>>({
        url: '/base/comm/aiChat',
        method: 'post',
        data
    })
}

export const changeAgentName = (data: IChangeAgentNameReq) => {
    return axios<Response<any>>({
        url: '/base/comm/changeAgentName',
        method: 'post',
        data
    })
}

export const getAiAgentReleaseList = (data: IGetAiAgentReleaseListReq) => {
    return axios<Response<IGetAiAgentReleaseListRes>>({
        url: '/base/comm/getAiAgentReleaseList',
        method: 'post',
        data
    })
}

export const createAiAgentRelease = (data: ICreateAiAgentRelease) => {
    return axios<Response<any>>({
        url: '/base/comm/createAiAgentRelease',
        method: 'post',
        data
    })
}

export const revokeRelease = (data: IRevokeRelease) => {
    return axios<Response<any>>({
        url: '/base/comm/revokeRelease',
        method: 'post',
        data
    })
}

export const getAiRepoList = (data: IGetAiRepoListReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/getList",
        method: "post",
        data
    })
}

export const createAiRepo = (data: ICreateAiRepoReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/create",
        method: "post",
        data
    })
}

export const addAiRepo = (data: IAddAiRepoReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/add",
        method: "post",
        data
    })
}

// commRoute.Auth.POST("/ai/repository/updateItem", clientAdmin.UpdateAiRepositoryItem)   // 修改知识库元素
export const updateAiRepoItem = (data: IUpdateAiRepoItemReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/updateItem",
        method: "post",
        data
    })
}
// commRoute.Auth.POST("/ai/repository/deleteItem", clientAdmin.DeleteAiRepositoryItem)   // 删除知识库元素
export const deleteAiRepoItem = (data: IDeleteAiRepoItemReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/deleteItem",
        method: "post",
        data
    })
}
// commRoute.Auth.POST("/ai/repository/delete", clientAdmin.DeleteAiRepository)           // 删除知识库
export const deleteAiRepo = (data: IDeleteAiRepoReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/delete",
        method: "post",
        data
    })
}
// commRoute.Auth.POST("/ai/repository/getItemList", clientAdmin.GetListAiRepositoryItem) // 获取知识库元素列表
export const getListAiRepoItem = (data: IGetListAiRepoItemReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/getItemList",
        method: "post",
        data
    })
}
// 		commRoute.Auth.POST("/ai/repository/changeOpenStatus", clientAdmin.ChangeOpenStatusAiRepository)
export const changeOpenStatusAiRepository = (data: IAiRepoChangeOpenStatusReq) => {
    return axios<Response<any>>({
        url: "/base/comm/ai/repository/changeOpenStatus",
        method: "post",
        data
    })
}

export const testTrainReply = (data: {
    basePrompt: string,
    aiAgentId: number,
    messages: Array<{
        type: 1|2,
        message: string,
    }>
}) => {
    return axios<Response<any>>({
        url: "/base/comm/testTrainReply",
        method: "post",
        data
    })
}