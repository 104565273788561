import axios from "/@/utils/request";
import { Response } from "/@/utils/request";
const api = {
  whiteList: "/base/comm/dataHasWhiteList",
};
export const postWhiteList = (data: any) => {
  return axios<Response<any>>({
    url: api.whiteList,
    method: "post",
    data,
  });
};
