import { defineStore } from 'pinia'
import {
    getPlatformAuth,
    login,
    loginParam,
    register,
    registerParam,
    emailcodeApi,
    loginOut,
    refreshToken,
    getPortsNum,
    reset,
    resetEmailcodeApi, initPlatformInfo
} from '/@/api/modules/user'
import {
    ILayout,
    IMenubarStatus,
    IMenubarList,
    ISetting,
    IMenubar,
    IStatus,
    IUserInfo,
    IPlatformAuth, IChannelInfo
} from '/@/type/store/layout'
import router from '/@/router/index'
import { allowRouter } from '/@/router/index'
import { setLocal, getLocal, decode } from '/@/utils/tools'
import type { AxiosResponse } from 'axios'
import UseMqtt from '/@/utils/UseMqtt'
import { importAllLocales } from '/@/utils/i18n'

const { addLink } = UseMqtt()
const setting = getLocal<ISetting>('setting')
const { ACCESS_TOKEN } = getLocal<IStatus>('token')

export const useLayoutStore = defineStore({
  id: 'layout',
  state: (): ILayout => ({
    menubar: {
      status:
        document.body.offsetWidth < 768
          ? IMenubarStatus.PHN
          : IMenubarStatus.PCE,
      menuList: [],
      isPhone: document.body.offsetWidth < 768,
    },
    /**
     * {name,username,expireTime,usedPort,multiAccount}
     * {名字,账号,已启用端口,可分配端口}
     */
    userInfo: {
      id: 0,
      name: '', //
      username: '',
      expireTime: '',
      usedPort: 0,
      multiAccount: 0,
      mode: 0,
      productId: 0,
      userLevel: 0,
      traffic: 0,
      nickName: '',
      isFree: '',
      resetPwd: 0,
      invitationHint: 0,
    },
    channelInfo: {
      name: '',
      name_en: '',
      WebLogo: '',
      LongLogo: '',
      UploadZip: '',
      ClientLogo: '',
      InstallLogo: '',
      UnInstallLogo: '',
      official_link: '',
      home_link: '',
      social_link: '',
      manual_link: '',
      serve_link: '',
      ChannelId: 0,
    },
    platformAuth: {
      list: [],
    },
    setting: {
      theme: setting.theme !== undefined ? setting.theme : 0,
      color: {
        primary:
          setting.color !== undefined ? setting.color.primary : '#409eff',
      },
      mode: setting.mode || 'vertical',
    },
    status: {
      isLoading: false,
      ACCESS_TOKEN: ACCESS_TOKEN || '',
    },
  }),
  getters: {
    getMenubar(): IMenubar {
      return this.menubar;
    },
    getUserInfo(): IUserInfo {
      return this.userInfo;
    },
    getChannelInfo(): IChannelInfo {
      return this.channelInfo;
    },
    getSetting(): ISetting {
      return this.setting;
    },
    getStatus(): IStatus {
      return this.status;
    },
    getPlatformAuth(): IPlatformAuth {
      return this.platformAuth;
    },
  },
  actions: {
    changeCollapsed(): void {
      this.menubar.status = this.menubar.isPhone
        ? this.menubar.status === IMenubarStatus.PHN
          ? IMenubarStatus.PHE
          : IMenubarStatus.PHN
        : this.menubar.status === IMenubarStatus.PCN
        ? IMenubarStatus.PCE
        : IMenubarStatus.PCN;
    },
    changeDeviceWidth(): void {
      this.menubar.isPhone = document.body.offsetWidth < 768;
      this.menubar.status = this.menubar.isPhone
        ? IMenubarStatus.PHN
        : IMenubarStatus.PCE;
    },
    async logout(): Promise<void> {
      try {
        await loginOut();
        this.reomveStorage(true);
      } catch (error) {}
    },
    reomveStorage(isExit?: boolean): void {
      this.status.ACCESS_TOKEN = '';
      localStorage.removeItem('token');
      localStorage.removeItem('platformAuth');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('loginStatus');
      if (isExit) history.go(0);
    },
    setToken(token: string): void {
      this.status.ACCESS_TOKEN = token;
      setLocal('token', this.status, 1000 * 60 * 60 * 24 * 6);
    },
    setRoutes(data: Array<IMenubarList>): void {
      this.menubar.menuList = data;
    },
    concatAllowRoutes(): void {
      allowRouter.reverse().forEach((v) => this.menubar.menuList.unshift(v));
    },
    // 登录
    async login(param: loginParam): Promise<void> {
      const res = await login(param);
      this.loginHandler(res.data.user, res.data.token);
    },
    loginHandler(userData: any, token: string) {
      this.userInfo = (({
        id,
        name,
        username,
        expireTime,
        usedPort,
        multiAccount,
        mode,
        productId,
        userLevel,
        traffic,
        nickName,
        isFree,
        countFans,
        resetPwd,
        invitationHint,
      }) => ({
        id,
        name,
        username,
        expireTime,
        usedPort,
        multiAccount,
        mode,
        productId,
        userLevel,
        traffic,
        nickName,
        isFree,
        countFans,
        resetPwd,
        invitationHint,
      }))(userData);
      localStorage.setItem('registerSource', userData.registerSource);
      this.setToken(token);
      localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
      this.getPlatformAuthFun();
      const { query } = router.currentRoute.value;
      // 处理第一次登陆
      localStorage.setItem('firstLogin', `${!userData.lastLoginTime}`);
      router.push(typeof query.from === 'string' ? decode(query.from) : '/');
    },
    // 注册
    async register(param: registerParam): Promise<void> {
      const res = await register(param);
  
      this.loginHandler(res.data.user, res.data.token);
    },
    // 忘记密码
    async reset(param: registerParam): Promise<void> {
      const res = await reset(param);
      router.push('/Login');
    },
    // 邮箱验证码
    async emailFn(param: any): Promise<void> {
      const res = await emailcodeApi(param);
    },
    // 邮箱验证码
    async resetEmailFn(param: any): Promise<AxiosResponse<IResponse>> {
      const res = await resetEmailcodeApi(param);
      return res;
    },
    // 平台权限
    async getPlatformAuthFun(): Promise<AxiosResponse<IResponse>> {
      const res = await getPlatformAuth();
      this.platformAuth.list = res.data?.reverse() ?? [];
      localStorage.setItem('platformAuth', JSON.stringify(this.platformAuth));
      return res;
    },
    // 刷新token
    async refreshToken(): Promise<AxiosResponse<IResponse>> {
      const res = await refreshToken();
      this.setToken(res.data.token);
      return res;
    },
    // 更新 用户信息
    async getPortsNum(): Promise<AxiosResponse<IResponse>> {
      const res = await getPortsNum();

      let userInfoData: any = localStorage.getItem('userInfo');
      userInfoData = JSON.parse(userInfoData);

      this.userInfo.multiAccount = res.data.totalMultiAccount;
      this.userInfo.usedPort = res.data.assignedMultiAccount;
      this.userInfo.userLevel = res.data.userLevel;
      this.userInfo.id = userInfoData.id;
      this.userInfo.name = userInfoData.name;
      this.userInfo.username = userInfoData.username;
      this.userInfo.expireTime = res.data.expireTime;
      this.userInfo.mode = res.data.mode;
      this.userInfo.productId = res.data.productId;
      this.userInfo.traffic = res.data.traffic;
      this.userInfo.nickName = userInfoData.nickName;
      this.userInfo.isFree = res.data.isFree;
      this.userInfo.countFans = res.data.countFans;
      this.userInfo.resetPwd = userInfoData.resetPwd;
      this.userInfo.invitationHint = userInfoData.invitationHint;

      localStorage.setItem('userInfo', JSON.stringify(this.userInfo));

      return res;
    },
    // 更新 渠道信息
    async getChannelData(): Promise<AxiosResponse<IResponse>> {
      const res = await initPlatformInfo();
      this.channelInfo.name = res.data.name;
      this.channelInfo.name_en = res.data.name_en;
      this.channelInfo.serve_link = res.data.serve_link;
      this.channelInfo.manual_link = res.data.manual_link;
      this.channelInfo.social_link = res.data.social_link;
      this.channelInfo.official_link = res.data.official_link;
      this.channelInfo.home_link = res.data.home_link;
      this.channelInfo.UploadZip = res.data.UploadZip;
      this.channelInfo.LongLogo = res.data.LongLogo;
      this.channelInfo.WebLogo = res.data.WebLogo;
      this.channelInfo.ClientLogo = res.data.ClientLogo;
      this.channelInfo.InstallLogo = res.data.InstallLogo;
      this.channelInfo.UnInstallLogo = res.data.UnInstallLogo;
      this.channelInfo.ChannelId = res.data.channelId;

      localStorage.setItem('channelInfo', JSON.stringify(res.data));
      return res;
    },
  },
});
