<template>
  <div class="no-p" v-if="isView">
    <span>{{ t('notSupport') }}</span>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n'

const { t } = useI18n()



const props = defineProps({
    userLevel: {
        type: Number,
        default: -1,
    }
})

const isView = ref(false)

onMounted(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
    if (props.userLevel > -1) {
        isView.value = userInfo.userLevel < props.userLevel
    }
})

</script>

<style>
.no-p {
    width: 100%;
    height: calc(100vh - 128px);
    position: absolute;
    top: 0;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 15px 10px rgba(255, 255, 255, 0.9);
    z-index: 20;
    text-align: center;
    line-height: calc(100vh - 256px);
}
</style>
