<template>
    <div>
        <el-form ref="formRef" label-width="auto" :model="formData" :rules="rules">
            <el-form-item v-if="mode == 'create'" label="知识库名称:" class="w-72" prop="name">
                <el-input v-model="formData.name" type="text" placeholder="请输入知识库名称"></el-input>

            </el-form-item>
            <div v-else class="mb-3">知识库名称: <span class="ml-1">{{ props.name }}</span></div>
            <el-form-item label="导入方式:" prop="type">
                <el-radio-group v-model="formData.type">
                    <el-radio label="file">本地上传</el-radio>
                    <el-radio label="link">网址链接</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <!-- TODO 此处限制上传文件数量使用 limit -->
                <el-upload
                    v-if="formData.type == 'file'"
                    class="upload-file w-full"
                    :action="fileUploadServerConfig.action"
                    :headers="fileUploadServerConfig.headers"
                    :before-upload="verifyFileSizeBeforeUpload"
                    :accept="fileRule"
                    :data="fileUploadServerConfig.data"
                    :auto-upload="true"
                    :on-success="handleUploadSuccess"
                    multiple
                    :limit="props.uploadLimit"
                    drag
                >
                    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                    <div class="el-upload__text">
                        <span>点击或托拽文件到此处上传</span>
                        <div style="font-size: 10px;height: 15px;line-height: 15px;">
                            仅支持文字/图片类型 (txt,md,docx,pdf,png,jpg,jpeg) 格式大小不得超过10M
                        </div>
                    </div>
                </el-upload>

                <el-input v-if="formData.type == 'link'" v-model="formData.links" type="textarea" :rows="8" placeholder="请输入网址地址，多个网址用换行分隔，最多可输入10个。" />
            </el-form-item>
            <el-form-item v-if="props.mode == 'create'" label="备注:" prop="remark">
                <el-input v-model="formData.remark" type="textarea" :rows="8" placeholder="有助于AI更好识别内容" />
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup lang="ts">
import {onUnmounted, reactive, ref, watch} from 'vue';
import { UploadFilled } from '@element-plus/icons-vue'
import { useLayoutStore } from '/@/store/modules/layout'
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n'
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';
import { useCommon } from '/@/hooks';
import { createAiRepo } from '/@/api/modules/ai';

const { emitter } = useCommon()
const { t } = useI18n()

const emits = defineEmits(['submit'])

const props = defineProps({
  name: {
    type: String,
    default: '没有获取到'
  },
  mode: {
    type: String,
    default: 'create'
  },
  uploadLimit: {
    type: Number,
    default: 10
  }
})

const { status } = useLayoutStore()
const fileRule = ref('.txt,.md,.docx,.pdf,.png,.jpg,.jpeg')
const formRef = ref(null)
const fileMaxSize = ref(10 * 1024 * 1024)
const formData = reactive({
    name: '', 
    type: 'file',
    remark: '',
    files: [] as string[],
    links: ''
})
const serviceUrl = import.meta.env.VITE_API_URL;
const fileUploadServerConfig = {
    action: `${serviceUrl}/base/comm/saveFile `,
    headers: {
        Authorization: `Bearer ${status.ACCESS_TOKEN}`
    },
    data: {
        type: "ai"
    }
}

onUnmounted(() => {
    emitter.off('submit')
})

const urlRegex = /^(https?:\/\/[^\s]+)$/;

emitter.on('submit', async () => {
    if (formData.type == 'file' && formData.files.length <= 0) {
        ElMessage.warning('请上传文件');
        emits('submit', null)
        return;
    }
    if (formData.type == 'link' && formData.links.length <= 0) {
        ElMessage.warning('请填写网址链接');
        emits('submit', null)
        return;
    }

    if (formData.type == 'link' && formData.links.split('\n').filter(line => urlRegex.test(line.trim())).map(url => url.trim()).length > props.uploadLimit) {
        ElMessage.warning(`输入链接超出最大条数`);
        emits('submit', null)
        return
    }

    try {
        await formRef.value.validate()
    } catch (e) {
        emits('submit', null)
        return
    }

    emits('submit', formData)
})

const handleUploadSuccess = (response: any, uploadFile: UploadFile, uploadFiles: UploadFile[]) => {
    if (response.code == 1000) {
        formData.files.push(response.data.filePath)
    }
}

const verifyFileSizeBeforeUpload = (file: File) => {
    if (!fileRule.value.includes('.' + file.name.split('.').pop())) {
        ElMessage.error(t('unsupportedFileTypes'));
        return false;
    }
    if (file.size > fileMaxSize.value) {
        ElMessage.error(t('fileSizeExceedsLimit'));
        return false;
    }
    return true; // 允许上传
}

const rules = {
    name: [
        { required: true, message: '请输入知识库名称', trigger: 'blur' },
        { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
    ],
    type: [
        { required: true, message: '请选择导入方式', trigger: 'change' }
    ]
}


</script>

<style scoped>
:deep(.el-upload-dragger), :deep(.el-upload) {
    width: 100%!important;
}
</style>