<template>
    <div class="repo-item-list">
        <con-top :title="name" :rowDescription="true">
            <el-button type="text" @click="openChangeRepoName"><i class="ri-edit-box-line"></i></el-button>
            <div class="float-right">
              <div class="mr-5 inline-block" style="font-size: 12px;">
                <span>文件数量 ({{ total }}/10)</span>
              </div>
              <el-button style="margin-top: -6px;" type="primary" class=" bg-blue-500 focus:bg-blue-500" @click="addRepoItem">上传文件</el-button>
              <el-button style="margin-top: -6px;" type="primary" class=" bg-blue-500 focus:bg-blue-500" v-if="hideBack !== '1'" @click="router.back()">返回</el-button>
            </div>
        </con-top>

        <div v-if="viewStatus == 'ok'">
            <div class="rpeo-item mt-5 border border-stone-300 rounded-sm px-4 py-2 bg-white flex" v-for="item in data">
                <div class="repo-item-img mr-4" v-if="item.is_img">
                    <el-image
                        :src="item.url_path"
                        :preview-src-list="[item.url_path]"
                    />
                </div>
                <div class="repo-item-content w-full">
                    <div class="rpeo-item-header flex flex-row">
                        <div class="rpeo-item-title basis-8/12"><span class="update-time">更新: {{ item.update_time }}</span><span class="mx-3">{{ {'file': '文件名', 'link': '链接'}[item.type] ?? '未知' }}：{{ item.type == 'file' ? item.file_name : item.path }}</span></div>
                        <div class="basis-4/12 text-right">
                          <span v-if="item.status == 0" class="mr-5 text-red-400">识别中</span>
                          <el-button  v-if="!item.isEdit && item.status == 1" type="text" class="py-0" style="margin-top: -3px;" @click="editMode(item)">编辑</el-button>
                          <el-popconfirm v-if="!item.isEdit && item.status == 2" cancel-button-type="text" confirm-button-type="text"  title="是否确认删除此项?" @confirm="deleteItem(item.ai_repository_item_id)">
                            <template #reference>
                              <el-button type="danger" size="small" class="mb-1 bg-red-500" @click="">删除</el-button>
                            </template>
                          </el-popconfirm>
                          <div v-if="item.isEdit">
                                <el-popconfirm title="是否确认删除此项?" cancel-button-type="text" confirm-button-type="text" @confirm="deleteItem(item.ai_repository_item_id)">
                                    <template #reference>
                                        <el-button type="danger" size="small" class="mb-1 bg-red-500" @click="">删除</el-button>
                                    </template>
                                </el-popconfirm>
                                <el-button size="small" class="mb-1 bg-white focus:bg-white" @click="closeEditMode(item)">取消</el-button>
                                <el-button type="primary" size="small" class="mb-1 bg-blue-500 focus:bg-blue-500" @click="updateItem(item)">保存</el-button>
                            </div>
                        </div>
                    </div>
                    <el-input
                        type="textarea"
                        autosize
                        resize="none"
                        class="mt-2"
                        :readonly="!item.isEdit"
                        v-model="item.content"
                        :placeholder="(() => {
                          if (item.status == 1 && item.content == '') {
                            return '没有任何内容'
                          }
                          if (item.status == 0) {
                            return '识别中'
                          } else if (item.status == 2) {
                            return '识别失败'
                          }
                        })()"
                        :class="{
                            'edit-mode': item.isEdit,
                        }"
                    ></el-input>
                </div>
            </div>
        </div>
        <!-- 空数据展示 -->
        <el-empty class="mt-32" v-if="viewStatus == 'null'" />
        <!-- 加载数据 -->
        <div v-if="viewStatus == 'loading'" class="mt-10">
            <el-skeleton :rows="10" animated />
        </div>
        <usual-dialog
            v-model:show="operState.show"
            v-model:loading="operState.loading"
            :clickModal="false"
            :title="operState.title"
            :width="operState.width"
            :show_footer="operState.showFooter"
        >
          <CreateRepository v-if="operState.optName == 'addRepo'" mode="add" :name="name" :upload-limit="10 - total" @submit="createSubmit" />
          <ChangeRepoName v-if="operState.optName == 'changeRepoName'" :name="name" @submit="submitChangeRepoName" />
        </usual-dialog>
    </div>
</template>

<script setup lang="ts">
// 获取url中的id
import {onMounted, reactive, ref} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  addAiRepo,
  changeAgentName,
  changeRepoName,
  deleteAiRepoItem,
  getListAiRepoItem,
  updateAiRepoItem
} from '/@/api/modules/ai';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import {IOperState} from "/@/type/views/code";
import ChangeRepoName from "/@/views/ai/agent/components/changeRepoName.vue";
import CreateRepository from "/@/views/ai/agent/components/createRepository.vue";

const { t } = useI18n();
const route = useRoute()
const router = useRouter()
const id = route.query.id
let name = route.query.name as string
const hideBack = (route.query?.hideBack ?? '0') as string

const data = ref([])
const total = ref(0)
const viewStatus = ref<'ok'|'loading'|'null'>('loading')

const operState = reactive<IOperState>({
  optName: "",
  show: false,
  width: "520px",
  title: "",
  showFooter: false,
  loading: false,
});

const addRepoItem = () => {
  operState.optName = "addRepo"
  operState.title = "上传文件"
  operState.width = '700px'
  operState.show = true
  operState.showFooter = true
}

const openChangeRepoName = () => {
  operState.optName = "changeRepoName"
  operState.title = "修改知识库名称"
  operState.width = '520px'
  operState.show = true
  operState.showFooter = true
}

const submitChangeRepoName = (data: {name: string}) => {
  changeRepoName({
    aiRepoId: Number(id),
    name: data.name,
  }).then(r => {
    if (r.code != 1000) {
      ElMessage.error(t('operationFailed'))
      return
    }
    ElMessage.success(t("operationSuccessful"))
    router.push({
      path: '/ai/repoItem',
      query: {
        id: Number(id),
        name: data.name,
      }
    })
    name = data.name
  }).finally(() => {
    operState.show = false
    operState.loading = false
  })
}

const createSubmit = (formData?: any) => {
  if (!formData) {
    operState.loading = false
    return
  }
  const urlRegex = /^(https?:\/\/[^\s]+)$/;

  addAiRepo({
    aiRepositoryId: Number(id),
    type: formData.type,
    files: formData.files,
    links: formData.links.split('\n').filter(line => urlRegex.test(line.trim())).map(url => url.trim()),
  }).then(r => {
    if (r.code != 1000) {
      ElMessage.error(t('operationFailed'))
      return
    }
    ElMessage.success(t("operationSuccessful"))
    getList()
  }).finally(() => {
    operState.loading = false
    operState.show = false
  })
}

const deleteItem = (itemId: number) => {
    deleteAiRepoItem({
        itemId,
    }).then(r => {
        if (r.code == 1000) {
            ElMessage.success(t('operationSuccessful'))
            getList()
        } else {
            ElMessage.error(t("operationFailed"))
            return
        }
    })
}
const updateItem = (item: any) => {
    updateAiRepoItem({
        itemId: item.ai_repository_item_id,
        content: item.content,
    }).then(r => {
        if (r.code == 1000) {
            ElMessage.success(t("operationSuccessful"))
            item.isEdit = false
            return
        } else {
            ElMessage.error(t("operationFailed"))
            return
        }
    })
}

const editMode = (item: any) => {
    item.isEdit = true
    item.oContent = item.content
}

const closeEditMode = (item: any) => {
    item.isEdit = false
    item.content = item.oContent
}

const getList = async () => {
    viewStatus.value = 'loading'
    getListAiRepoItem({
        aiRepositoryId: Number(id),
        page: 1,
        size: 100,
    }).then(r => {
        if (r.code == 1000) {
            if ((r.data.list?.length ?? 0) > 0) {
                viewStatus.value = 'ok'
                data.value = r.data.list
                total.value = r.data.total
            } else {
                viewStatus.value = 'null'
            }
        }
    }).catch(() => {
        viewStatus.value = 'null'
    })
}

onMounted(() => {
    getList()
})

</script>

<style scoped>
.rpeo-item > .rpeo-item-title {
    font-size: 1rem;
    line-height: 32px
}
.rpeo-item-header {
    line-height: 32px
}
.rpeo-item > .rpeo-item-title > .update-time {
    font-size: 0.8rem;
}
.rpeo-item > .repo-item-img {
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center; /* 如果水平也需要居中 */
}

.rpeo-item > .repo-item-img > :deep(.el-image) > img {
    max-height: 328px;
}

.edit-mode :deep(.el-textarea__inner) {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    max-height: 300px!important;
    min-height: 250px!important;
    height: 100% !important;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    color: var(--el-input-text-color, var(--el-text-color-regular));
    background-color: #edf5ff;
    background-image: none;
    border: #82b8ff solid 1px;
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
    transition: var(--el-transition-border);
    cursor: text;
}

:deep(.el-textarea__inner) {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    max-height: 300px!important;
    min-height: 250px!important;
    height: 100% !important;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    color: var(--el-input-text-color, var(--el-text-color-regular));
    background-color: #f4f4f4;
    background-image: none;
    border: var(--el-input-border, var(--el-border-base));
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
    transition: var(--el-transition-border);
    cursor: default;
}
:deep(.illustrate) {
  margin-left: 10px!important;
}
</style>