<template>
  <div class="code-box">
    <NoPermissions :user-level="2" />
    <con-top title="AI知识库" :rowDescription="true">
      <div class="float-right">
        <el-button @click="openCreateRepository" style="margin-top: -5px" class="bg-blue-600 text-white border-blue-400 hover:bg-blue-400 focus:bg-blue-400 hover:text-white focus:text-white">创建知识库</el-button>
      </div>
    </con-top>
    <section class="section search-box">
      <el-row>
        <el-col :span="6" class="flex">
          <span class="label leading-10 pl-9">知识库标题：</span>
          <el-input class="flex-1 input" v-model="searchData.name" placeholder="请输入知识库标题" ></el-input>
        </el-col>
        <el-col :span="18" class="flex justify-end pl-9">
          <el-button type="primary" class="btn-style select-btn" @click="searchEvent">{{ t('fansLabelList4') }}</el-button>
          <el-button plain class="btn-style reset-btn" @click="resetEvent">{{ t('fansLabelList5') }}</el-button>
        </el-col>
      </el-row>
    </section>
    <section class="section list-box">
      <usual-table
          :loading="tableData.loading"
          :columns="tableData.columns"
          :list="tableData.list"
          :total="tableData.total"
          v-model:page-num="searchData.page"
          v-model:page-size="searchData.size"
          @getList="getList"
      >
        <template #operate>
          <el-table-column :label="t('fansLabelList8')" width="120" align="center">
            <template #default="scope">
              <div class="flex cursor-pointer select-none">
                <div class="text-blue-400 mx-1" @click="gotoRepoItemView(scope.row.aiRepositoryId, scope.row.name, scope.row.status)">查看</div>
                <el-popconfirm title="是否确认删除?" @confirm="deleteAiRepoEvent(scope.row.aiRepositoryId)" :confirm-button-type="'text'">
                  <template #reference>
                    <div class="mx-1 text-red-500">删除</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </template>
      </usual-table>
    </section>
    <usual-dialog
        v-model:show="operState.show"
        v-model:loading="operState.loading"
        :clickModal="false"
        :title="operState.title"
        :width="operState.width"
        :show_footer="operState.showFooter"
    >
      <CreateRepository @submit="createSubmit" />
    </usual-dialog>
  </div>
</template>

<script setup lang="ts">
import NoPermissions from "/@/views/Talk/components/NoPermissions.vue";
import {reactive} from "vue";
import {TableColumn} from "/@/type/base";
import {createAiRepo, deleteAiRepo, getAiRepoList} from "/@/api/modules/ai";
import { useI18n } from 'vue-i18n'
import {IOperState} from "/@/type/views/code";
import CreateRepository from "/@/views/ai/agent/components/createRepository.vue";
import {ElMessage} from "element-plus";
import router from "/@/router";

const { t } = useI18n()

const operState = reactive<IOperState>({
  optName: "",
  show: false,
  width: "520px",
  title: "",
  showFooter: false,
  loading: false,
});

const openCreateRepository = () => {
  operState.optName = "create"
  operState.title = "新建知识库"
  operState.width = '700px'
  operState.show = true
  operState.showFooter = true
}

const tableData = reactive({
  loading: false,
  list: [],
  columns: [
    {label: "知识库名称", prop: 'name', align: "left"},
    {label: '状态', prop: 'status', align: "left", formatter: (row: any) => {
      return ['进行中', '成功', '失败'][row.status]
    }},
    {label: '内容', prop: 'contentCount', align: "left", formatter: (row: any) => {
      let typeName = '文档'
      // switch (row.type) {
      //   case 'file':
      //     typeName = '文件'
      //     break
      //   case 'link':
      //     typeName = '链接'
      //     break
      // }
      return `${row.contentCount}个${typeName}`
    }},
    {label: '备注', prop: 'remark', align: "left", formatter: row => row.remark != '' ? row.remark : '无'},
    {label: '最后更新时间', align: "left", prop: 'updateTime'}
  ] as TableColumn[],
  total: 0,
})

// 跳转到知识库详情页
const gotoRepoItemView = (aiRepositoryId: number, name: string, status: number) => {
  // if (status == 0) {
  //   ElMessage.warning("知识库未初始化完成不能操作")
  //   return
  // }
  router.push({
    path: '/ai/repoItem',
    query: {
      id: aiRepositoryId,
      name: name,
    }
  })
}

// 删除知识库
const deleteAiRepoEvent = (aiRepositoryId: number) => {
  deleteAiRepo({
    aiRepositoryId: aiRepositoryId,
  }).then(r => {
    if (r.code != 1000) {
      ElMessage.error(t('operationFailed'))
      return
    }
    ElMessage.success(t("operationSuccessful"))
    getAiRepoListReq()
  })
}

const createSubmit = (formData?: any) => {
  if (!formData) {
    operState.loading = false
    return
  }
  const urlRegex = /^(https?:\/\/[^\s]+)$/;

  createAiRepo({
    // aiAgentId: props.aiAgentId,
    name: formData.name,
    type: formData.type,
    remark: formData.remark,
    files: formData.files,
    links: formData.links.split('\n').filter(line => urlRegex.test(line.trim())).map(url => url.trim()),
  }).then(r => {
    if (r.code != 1000) {
      ElMessage.error(t('operationFailed'))
      return
    }
    ElMessage.success(t("operationSuccessful"))
    getAiRepoListReq()
  }).finally(() => {
    operState.loading = false
    operState.show = false
  })
}

const searchEvent = () => {
  getAiRepoListReq()
}

const resetEvent = () => {
  searchData.name = ''
  getAiRepoListReq()
}

const searchData = reactive({
  page: 1,
  size: 10,
  name: '',
})

const getList = () => {
  getAiRepoListReq()
}

const getAiRepoListReq = () => {
  tableData.loading = true
  getAiRepoList({
    page: searchData.page,
    size: searchData.size,
    name: searchData.name
  }).then(r => {
    tableData.list = r.data.list ?? []
    tableData.total = r.data.total ?? 0
  }).finally(() => {
    tableData.loading = false
  })
}

</script>

<style scoped>

.section {
  padding: 30px;
  margin: 30px 0;
  background-color: #fff;
  border-radius: 10px;
}
.label {
  color: #262e44;
  font-size: 14px;
  font-weight: 500;
}

.select-btn {
  min-width: 90px;
  height: 36px;
  background: #fbc100;
  border: 0;
  color: #fdfdfe;
}

.search-box {
  padding-left: 0;

  ::v-deep(.el-input__inner) {
    height: 40px;
  }
}

:deep(.select-btn):focus, :deep(.select-btn):hover {
  background-color: #fbc100!important;
}

:deep(.reset-btn):focus, :deep(.reset-btn):hover {
  background-color: #fff!important;
}

.reset-btn {
  min-width: 90px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #1e75ff;
  color: #1e75ff;
}

</style>